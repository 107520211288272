const $addToCart = $(".js-add-to-cart");
var isAjax = false;

$addToCart.on("submit", function (e) {
	e.preventDefault();

	if (isAjax) {
		// Prevent multiple simultaneous AJAX requests
		return;
	}

	const $this = $(this);
	let data = $this.serializeArray();
	data.push({ name: "action", value: "app_add_to_cart" });

	const settings = {
		url: my_ajax_obj.ajax_url,
		method: "POST",
		data: $.param(data), // Serializing the data correctly
		beforeSend: function () {
			isAjax = true;
		},
	};

	$.ajax(settings)
		.done(function (response) {
			console.log(response.data.cart_html);
			$(".js-cart-items").replaceWith(response.data.cart_html);
			toggleCart();
		})
		.fail(function (jqXHR, textStatus, errorThrown, response) {
			console.log(response.data);
			console.log("Error: " + textStatus, errorThrown);
		})
		.always(function () {
			isAjax = false;
		});
});

const toggleCart = () => {
	$(".shop-cart").toggleClass("is-active");
	$(".overlay").toggleClass("is-active");
	$("body").toggleClass("overflow-hidden");
};

$(".overlay").on("click", function (e) {
	toggleCart();
});

$(".js-close-cart").on("click", function () {
	toggleCart();
});

document.addEventListener("DOMContentLoaded", function () {
	// Verkrijg het prijsveld
	var formPrice = document.getElementById("input_1_3");
	var priceField = document.getElementById("input_1_3");

	if (priceField) {
		// Voorbeeld: Verkrijg dynamische prijswaarde (hier kan je je eigen logica gebruiken)
		// Hier wordt een waarde uit een sessievariabele gehaald (pas dit aan naar je eigen situatie)
		var cartTotal = "0,00 €"; // Vervang dit met je eigen dynamische waarde

		// Stel de waarde in op het prijsveld
		priceField.value = cartTotal;
	}
});
