import Cookies from 'js-cookie';

$('.js-add-to-quote').on('click', function(e) {
	const $this = $(this);
	const currentId = $this.data('product-id');

	let productIds = Cookies.get('productIds');

	if(productIds) {
		productIds = productIds.split(','); // if 'productIds' cookie exists, convert it to an array
		if(!productIds.includes(currentId.toString())) { // if 'currentId' not in 'productIds' array
			productIds.push(currentId); // add 'currentId' to 'productIds' array
			Cookies.set('productIds', productIds.join(',')); // update the 'productIds' cookie
		}
	} else {
		Cookies.set('productIds', currentId); // if 'productIds' cookie doesn't exist, create it and set it to 'currentId'
	}

	console.log(Cookies.get('productIds')); // for testing purpose, print 'productIds' cookie to the console
});


