import "./modules/forms.js";
import "./modules/sliders.js";
import "./modules/search-ui.js";
import "./modules/accordions.js";
import "./modules/mobile-navigation.js";
import "./modules/videos.js";
import "./modules/quotation.js";
import "./modules/add-to-cart.js";

// Locatie filter
$(".js-location-filter").on("change", function () {
	$("#location-filter-form").trigger("submit");
});

window.onscroll = function () {
	myFunction();
};

var header = document.getElementById("header");
var sticky = header.offsetTop;

function myFunction() {
	if (window.pageYOffset > sticky) {
		header.classList.add("sticky");
	} else {
		header.classList.remove("sticky");
	}
}

$('input[name="acceptance-273"]').on("change", function (e) {
	const $this = $(e.currentTarget);
	// const checked = $this.prop("checked");
	$this.closest("label").toggleClass("checked");
});
