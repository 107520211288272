import Swiper from 'swiper/bundle';

$('.js-slider-testimonials .swiper-container').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-testimonials');
	const sliderPagination = $slider.find('.slider__paging')[0];

	const swiper = new Swiper(slider, {
		slidesPerView: 1,
		resistanceRatio: 0,
		spaceBetween: 13,
		loop: true,
		speed: 1000,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},
		pagination: {
			el: sliderPagination,
			clickable: true,
		},
	});
});

$('.js-slider-products .swiper-container').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-products');
	const nextEl = $slider.find('.slider__next')[0];
	const prevEl = $slider.find('.slider__prev')[0];

	const swiper = new Swiper(slider, {
		slidesPerView: 1,
		resistanceRatio: 0,
		spaceBetween: 16,
		loop: true,
		speed: 1000,
		navigation: {
			nextEl,
			prevEl,
		},

		breakpoints: {
			centeredSlides: true,
			768: {
				slidesPerView: 2,
			},

			1200: {
				slidesPerView: 3,
			}
		}
	});
});

$('.js-slider-images .swiper-container').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-images');
	const nextEl = $slider.find('.slider__next')[0];
	const prevEl = $slider.find('.slider__prev')[0];

	const swiper = new Swiper(slider, {
		slidesPerView: 1,
		resistanceRatio: 0,
		speed: 1000,
		loop: true,
		navigation: {
			nextEl,
			prevEl,
		},
	});
});

$('.js-slider-projects .swiper-container').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-projects');
	const nextEl = $slider.find('.slider__next')[0];
	const prevEl = $slider.find('.slider__prev')[0];

	if ($(slider).find('.slider__slide').length < 2) {
		$(slider).addClass('is-not-active');

		return;
	}


	const mySwiper = new Swiper(slider, {
		autoplay: false,
		slidesPerView: 'auto',
		spaceBetween: 16,
		breakpoints: {
			320: {
				spaceBetween: 10,
			},
			768: {
				spaceBetween: 16,
			}
		}
	});

});

$('.js-slider-images-complex').each(function(index, slider) {
	const $slider = $(slider);
	const thumbsContainer = $slider.find('.slider__clip-thumbs')[0]
	const mainContainer = $('.slider__clip-main')[0];

	console.log(mainContainer);
	console.log(thumbsContainer);

	const thumbsSlider = new Swiper(thumbsContainer, {
		spaceBetween: 20,
		slidesPerView: 'auto',
		watchSlidesProgress: true,
		clickable: true,
	});

	const mainSlider = new Swiper(mainContainer, {
		spaceBetween: 20,
		thumbs: {
			swiper: thumbsSlider
		},
	});
});


