$('.js-accordion-questions .accordion__head').on('click', function(e) {
	const $currentTarget = $(this).closest('.accordion__section');

	$currentTarget
		.toggleClass('is-current')
		.find('.accordion__body')
			.slideToggle()
			.end()

		.siblings()
			.removeClass('is-current')
			.find('.accordion__body')
				.slideUp();

});


