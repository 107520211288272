// $('.js-video').each(function (i, videoContainer) {
// 	const $videoContainer = $(videoContainer);
// 	const $video = $videoContainer.find('video')[0];
// 	const playVideoButton = $videoContainer.find('.js-play-video');
//
//
// 	playVideoButton.on('click', function(e) {
// 		e.preventDefault();
//
// 		$videoContainer.addClass('is-active');
//
// 		$video.play();
// 	});
// });


const video_wrapper = jQuery('.video-iframe');
const video_holder = jQuery('.video__figure');
const video_thumbnail = jQuery('.js-video');
//  Check to see if youtube wrapper exists
if(video_wrapper.length && video_thumbnail){
	// If user clicks on the video wrapper load the video.
	video_thumbnail.on('click', function(e){
		e.preventDefault();

		video_thumbnail.addClass('is-active');

		/* Dynamically inject the iframe on demand of the user.
		Pull the youtube url from the data attribute on the wrapper element. */
		video_holder.html('<iframe allow="autoplay" frameborder="0" class="embed-responsive-item" src="' + video_wrapper.data('yt-url') + '"></iframe>');
	});
}
